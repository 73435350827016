import React from 'react';
import {marked} from 'marked';
import uiTexts from 'data/ui-texts';

/**
 * Parse markdown text
 * @param {string} marked
 * @return {html}
 */
export function renderMarkdown(markdown) {
	if (markdown && typeof markdown === 'string' && markdown.length > 0) {
		let lexed = marked.lexer(markdown);
		let parsed = marked.parser(lexed);
		return <div dangerouslySetInnerHTML={{__html: parsed}} />;
	}
	return <div />;
}

/**
 * Get UI text
 * @param {string} textListId 
 * @param {string} textId 
 * @returns 
 */
export function getText (textListId, textId) {
	return uiTexts[textListId][textId];
};